* {
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth;
  font-family: sans-serif;
  font-display: swap;
}

a,
nav,
ul,
li {
  text-decoration: none;
  list-style: none;
}

img {
  width: inherit;
}

input,
textarea,
button {
  border: none;
  padding: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #bfa45f;
  border-radius: 8px;
}

button {
  cursor: pointer;
}

body {
  overflow-x: hidden;
}

h1 {
  font-size: 2.3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

p {
  font-size: 1.2rem;
}

@media screen and (max-width: 1220px) {
  h1 {
    font-size: 2.1rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  p {
    font-size: 0.8rem;
  }
}
